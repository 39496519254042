import { FC, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Chevron from '../Chevron';
import s from '../Accordion/style/Accordion.module.scss';

const TableAccordion: FC = ({ children }) => {
  const content = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<string>('0px');

  function toggleAccordion(): void {
    setIsActive(!isActive);
    setTimeout(() => {
      setContentHeight(
        isActive ? '0px' : `calc(${content?.current?.scrollHeight}px + 10px)`
      );
    }, 60);
  }

  return (
    <>
      <Button
        variant="outlined"
        endIcon={
          <Chevron isActive={!isActive} width="10" height="18" fill="#2D549A" />
        }
        style={{
          textTransform: 'none',
          borderColor: '#2D549A',
          color: '#2D549A',
        }}
        onClick={() => toggleAccordion()}
      >
        {isActive ? 'Hide data' : 'Show data'}
      </Button>
      <div
        ref={content}
        className={`${s.accordion__content} ${
          !isActive && s.accordion__content__noVisible
        }`}
        style={{ maxHeight: contentHeight }}
      >
        {children}
      </div>
    </>
  );
};

export default TableAccordion;
