/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios';
import humps from 'humps';
import AuthService from './client';
import { appConfig } from '../config/appConfig';

const auth = new AuthService();
const httpClient = axios.create({
  baseURL: appConfig.api,
  // `validateStatus` defines whether to resolve or reject the promise for a given
  // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
  // or `undefined`), the promise will be resolved; otherwise, the promise will be
  // rejected.
  validateStatus(status) {
    return status >= 200 && status < 302; // default
  },
});
// httpClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
httpClient.defaults.withCredentials = true;
httpClient.interceptors.request.use(async (config: any) => {
  const stringToken = window.localStorage.getItem('token');
  if (stringToken) {
    const token = stringToken;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});
httpClient.interceptors.response.use(
  async (response) => {
    const { data } = humps.camelizeKeys({ ...response }) as AxiosResponse;
    return Promise.resolve(data);
  },
  async (error) => {
    if (error.response.status === 401) {
      auth.login({ redirectUrl: window.location.href });
      localStorage.removeItem('token');
      localStorage.removeItem('idToken');
    }
    return Promise.reject(error.response);
  }
);
export default httpClient;
