import { FC, useState, useRef } from 'react';
import Chevron from '../Chevron';
import s from './style/Accordion.module.scss';

interface IAccordion {
  title: string;
  // eslint-disable-next-line react/require-default-props
  childrenExtraHeight?: string;
}

const Accordion: FC<IAccordion> = ({
  title,
  children,
  childrenExtraHeight = '0',
}) => {
  const content = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<string>('900px');

  function toggleAccordion(): void {
    setIsActive(!isActive);
    setContentHeight(isActive ? '0px' : `calc(${childrenExtraHeight}px)`);
  }

  return (
    <div className={`${s.accordion__section}`}>
      <div className={`${s.header}`}>
        <h4 className={`${s.header__title}`}>{title}</h4>
        <button
          title="button"
          type="button"
          className={`${s.button}`}
          onClick={toggleAccordion}
        >
          <Chevron isActive={!isActive} width="10" height="18" fill="#2D549A" />
        </button>
      </div>
      <div
        ref={content}
        className={`${s.accordion__content} ${
          !isActive && s.accordion__content__noVisible
        }`}
        style={{ maxHeight: contentHeight }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
