import { FC } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import s from '../../style/LocationProfilePage.module.scss';
import Accordion from '../../../../components/Accordion';

interface IMedianIncomeBlock {
  data: {
    medianIncome: string;
    perCapitaIncome: string;
    personsBelowPovertyLine: number;
  };
  isLoading: boolean;
}

const MedianIncomeBlock: FC<IMedianIncomeBlock> = ({ data, isLoading }) => (
  <div className={`${s.block} ${s.block__number}`}>
    <Accordion title="Income" childrenExtraHeight="900">
      <div className={`${s.block__number__body}`}>
        <div className={`${s.block__number__body__item}`}>
          <h4 className={`${s.block__number__body__item__value}`}>
            {isLoading ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              `$${data.medianIncome}`
            )}
          </h4>
          <p className={`${s.block__number__body__item__label}`}>
            Median household income
          </p>
        </div>
        <div className={`${s.block__number__body__item}`}>
          <h4 className={`${s.block__number__body__item__value}`}>
            {isLoading ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              `$${data.perCapitaIncome}`
            )}
          </h4>
          <p className={`${s.block__number__body__item__label}`}>
            Per capita income
          </p>
        </div>
        <div className={`${s.block__number__body__item}`}>
          <h4 className={`${s.block__number__body__item__value}`}>
            {isLoading ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              `${data.personsBelowPovertyLine}%`
            )}
          </h4>
          <p className={`${s.block__number__body__item__label}`}>
            Population below the poverty line
          </p>
        </div>
      </div>
    </Accordion>
  </div>
);

MedianIncomeBlock.propTypes = {
  data: PropTypes.shape({
    medianIncome: PropTypes.string.isRequired,
    perCapitaIncome: PropTypes.string.isRequired,
    personsBelowPovertyLine: PropTypes.number.isRequired,
  }).isRequired,
};
export default MedianIncomeBlock;
