import { FC } from 'react';
import PropTypes from 'prop-types';
import s from '../../style/LocationProfilePage.module.scss';
import PureTable from '../../../../components/PureTable';
import Accordion from '../../../../components/Accordion';

interface IData {
  name: string;
  value: number;
}

type IBusinessWorkedBlock = {
  data: IData[];
  isLoading: boolean;
};

const BusinessWorkedBlock: FC<IBusinessWorkedBlock> = ({ data, isLoading }) => (
  <div className={`${s.block} ${s.block__table}`}>
    <Accordion title="Industry (% of Population)" childrenExtraHeight="900">
      <div className={`${s.block__table__pureBody}`}>
        {isLoading ? (
          <tr>
            <td colSpan={10000}>Loading...</td>
          </tr>
        ) : (
          <PureTable data={data} headerData={['Type', 'Value']} prefix="%" />
        )}
      </div>
    </Accordion>
  </div>
);

BusinessWorkedBlock.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
};

export default BusinessWorkedBlock;
