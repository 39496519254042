import { FC } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import BarChart from '../../../../components/BarChart';
import s from '../../style/LocationProfilePage.module.scss';
import TableAccordion from '../../../../components/TableAccordion';
import PureTable from '../../../../components/PureTable';
import { IAgePopulation } from '../../../../models/IDemographic';

interface IAgeChart {
  chartData: number[];
  isLoading: boolean;
  ageTableData: IAgePopulation<string>;
}

const AgeChart: FC<IAgeChart> = ({ chartData, ageTableData, isLoading }) => {
  const tableData = [
    {
      value: ageTableData.from0to9,
      name: '0 - 9 years',
    },
    {
      value: ageTableData.from10to19,
      name: '10 - 19 years',
    },
    {
      value: ageTableData.from20to29,
      name: '20 - 29 years',
    },
    {
      value: ageTableData.from30to39,
      name: '30 - 39 years',
    },
    {
      value: ageTableData.from40to49,
      name: '40 - 49 years',
    },
    {
      value: ageTableData.from50to59,
      name: '50 - 59 years',
    },
    {
      value: ageTableData.from60to69,
      name: '60 - 69 years',
    },
    {
      value: ageTableData.from70to79,
      name: '70 - 79 years',
    },
    {
      value: ageTableData.from80AndOver,
      name: '80 or more',
    },
  ];
  return (
    <div className={`${s.block} ${s.block__chart}`}>
      {isLoading ? (
        <div style={{ marginLeft: '12px' }}>
          <h4 className={`${s.table__pie__title}`}>
            Population by age range (%)
          </h4>
          <Skeleton width="100%" height="230px" />
        </div>
      ) : (
        <BarChart
          isEmpty
          legendTitle={[
            {
              name: '',
              textStyle: {
                color: '#2E5596',
              },
            },
          ]}
          data={{
            barWidth: '35%',
            emphasis: {
              focus: 'series',
            },
            blur: {
              itemStyle: {
                color: 'rgba(33, 3, 3, 1)',
              },
            },
            name: '',
            type: 'bar',
            label: {
              show: true,
              color: 'inherit',
              position: 'top',
              valueAnimation: true,
              formatter: '{@score}%',
            },
            data: chartData,
          }}
          title="Population by age range (%)"
          legendData={[
            '0-9',
            '10-19',
            '20-29',
            '30-39',
            '40-49',
            '50-59',
            '60-69',
            '70-79',
            '80 or more',
          ]}
        />
      )}
      <div className={`${s.block__chart__body}`}>
        <TableAccordion>
          <div style={{ height: isLoading ? '90px' : '100%' }}>
            <h4 className={`${s.table__pie__title}`}>
              Population by age range (number)
            </h4>
            <PureTable
              data={tableData}
              headerData={['Type', 'Value']}
              isBigTable
            />
          </div>
        </TableAccordion>
      </div>
    </div>
  );
};

AgeChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  ageTableData: PropTypes.shape({
    from0to9: PropTypes.string.isRequired,
    from10to19: PropTypes.string.isRequired,
    from20to29: PropTypes.string.isRequired,
    from30to39: PropTypes.string.isRequired,
    from40to49: PropTypes.string.isRequired,
    from50to59: PropTypes.string.isRequired,
    from60to69: PropTypes.string.isRequired,
    from70to79: PropTypes.string.isRequired,
    from80AndOver: PropTypes.string.isRequired,
  }).isRequired,
};

export default AgeChart;
