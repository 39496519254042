import { FC } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import BarChart from '../../../../components/BarChart';
import s from '../../style/LocationProfilePage.module.scss';
import TableAccordion from '../../../../components/TableAccordion';
import PureTable from '../../../../components/PureTable';
import { IRacePopulation } from '../../../../models/IDemographic';

interface IRaceChart {
  chartData: number[];
  raceTableData: IRacePopulation<string>;
  isLoading: boolean;
}

const RaceChart: FC<IRaceChart> = ({ chartData, raceTableData, isLoading }) => {
  const raceData = [
    {
      value: raceTableData.raceWhite,
      name: 'White alone',
    },
    {
      value: raceTableData.raceHispanic,
      name: 'Hispanic',
    },
    {
      value: raceTableData.raceBlack,
      name: 'Black or African American alone',
    },
    {
      value: raceTableData.raceAsian,
      name: 'Asian alone',
    },
    {
      value: raceTableData.raceNative,
      name: 'American Indian and Alaska Native alone',
    },
    {
      value: raceTableData.raceIslander,
      name: 'Native Hawaiian and other Pacific Islander alone',
    },
    {
      value: raceTableData.raceOther,
      name: 'Some other race alone',
    },
    {
      value: raceTableData.raceTwoOrMoreRaces,
      name: 'Two or more races',
    },
  ];
  return (
    <div className={`${s.block} ${s.block__chart}`}>
      {isLoading ? (
        <div style={{ marginLeft: '12px' }}>
          <h4 className={`${s.table__pie__title}`}>
            Race & Hispanic Origin (%)
          </h4>
          <Skeleton width="100%" height="230px" />
        </div>
      ) : (
        <BarChart
          isEmpty
          legendTitle={[
            {
              name: '',
              textStyle: {
                color: '#2E5596',
              },
            },
          ]}
          data={{
            barWidth: '35%',
            emphasis: {
              focus: 'series',
            },
            blur: {
              itemStyle: {
                color: 'rgba(33, 3, 3, 1)',
              },
            },
            name: '',
            type: 'bar',
            label: {
              show: true,
              color: 'inherit',
              position: 'top',
              valueAnimation: true,
              formatter: '{@score}%',
            },
            data: chartData,
          }}
          title="Race & Hispanic Origin (%)"
          legendData={[
            'White',
            'Hispanic',
            'Black',
            'Asian',
            'American Indian',
            'Pacific Islander',
            'Other',
            'Two or more',
          ]}
        />
      )}
      <div className={`${s.block__chart__body}`}>
        <TableAccordion>
          <div style={{ height: isLoading ? '90px' : '100%' }}>
            <h4 className={`${s.table__pie__title}`}>
              Race & Hispanic Origin (number)
            </h4>
            {isLoading ? (
              <tr>
                <td colSpan={10000}>Loading...</td>
              </tr>
            ) : (
              <PureTable data={raceData} isBigTable />
            )}
          </div>
        </TableAccordion>
      </div>
    </div>
  );
};

RaceChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  raceTableData: PropTypes.shape({
    totalPopulation: PropTypes.string.isRequired,
    raceWhite: PropTypes.string.isRequired,
    raceBlack: PropTypes.string.isRequired,
    raceNative: PropTypes.string.isRequired,
    raceAsian: PropTypes.string.isRequired,
    raceIslander: PropTypes.string.isRequired,
    raceOther: PropTypes.string.isRequired,
    raceTwoOrMoreRaces: PropTypes.string.isRequired,
    raceHispanic: PropTypes.string.isRequired,
  }).isRequired,
};

export default RaceChart;
