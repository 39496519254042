import { createTheme } from '@mui/material/styles';
// import { createMuiTheme } from '@material-ui/core/styles';

const CUCTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      color: '#000',
      backgroundColor: 'white',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#15e577',
        borderColor: '#564345',
      },
    },
  },
  palette: {
    primary: {
      main: '#2CB392',
      contrastText: '#FFFFFF',
    },

    secondary: {
      main: '#FFFFFF',

      contrastText: '#2e5596',
    },
  },
});

export default CUCTheme;
