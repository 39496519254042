import { FC } from 'react';
import { Skeleton } from '@mui/material';
import s from '../../style/LocationProfilePage.module.scss';
import TableAccordion from '../../../../components/TableAccordion';
import Accordion from '../../../../components/Accordion';

const LoadingPieChart: FC<{ title: string }> = ({ title }) => (
  <div className={`${s.block} ${s.block__pie}`}>
    <Accordion title={title} childrenExtraHeight="900">
      <div className={s.block__pie__body}>
        <Skeleton width="100%" height="300px" />
        <div className={s.block__pie__body__table}>
          <TableAccordion>
            <>
              <h4 className={`${s.table__pie__title}`}>{title}</h4>
              <div>
                <span style={{ margin: '0px 4px' }}>
                  <Skeleton width="100%" height="100%" />
                </span>
                <span style={{ margin: '0px 4px' }}>
                  <Skeleton width="100%" height="100%" />
                </span>
                <span style={{ margin: '0px 4px' }}>
                  <Skeleton width="100%" height="100%" />
                </span>
              </div>
            </>
          </TableAccordion>
        </div>
      </div>
    </Accordion>
  </div>
);

export default LoadingPieChart;
