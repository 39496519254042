/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { IDENTITY_CONFIG } from './authConst';

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    this.userManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
    });
  }

  public getUser(): Promise<any> {
    return this.userManager.getUser();
  }

  public login({ redirectUrl }: { redirectUrl: string }): Promise<void> {
    localStorage.setItem('redirectUrl', redirectUrl);
    return this.userManager.signinRedirect();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('idToken'),
    });
  }

  public getAccessToken(): Promise<string> {
    return this.userManager.getUser().then((data: any) => data);
  }
}
