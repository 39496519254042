import { FC } from 'react';
import PropTypes from 'prop-types';
import s from './style/PureTable.module.scss';

interface ITableData {
  name: string;
  value: number | string;
}

interface IPureTable {
  data: ITableData[];
  prefix?: string;
  isBigTable?: boolean;
  headerData?: string[];
}

const PureTable: FC<IPureTable> = ({
  data,
  prefix,
  headerData,
  isBigTable = false,
}) => {
  const tableHeader = (): JSX.Element => (
    <tr className={`${s.table__header__row}`}>
      {headerData?.map((i) => (
        <td className={`${s.table__header__row__text}`} key={i}>
          {i}
        </td>
      ))}
    </tr>
  );

  const mapList = (): JSX.Element[] =>
    data.map((i) => (
      <tr className={`${s.table__body__row}`} key={i.name}>
        <td className={`${s.table__body__row__text}`}>{i.name}</td>
        <td className={`${s.table__body__row__text}`}>
          {`${i.value}${prefix}`}
        </td>
      </tr>
    ));
  return (
    <table
      className={`${s.table}`}
      style={{ marginBottom: !isBigTable ? '24px' : '24px' }}
    >
      {headerData && (
        <thead className={`${s.table__header}`}>{tableHeader()}</thead>
      )}
      <tbody className={`${s.table__body}`}>{mapList()}</tbody>
    </table>
  );
};

PureTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }).isRequired
  ).isRequired,
  prefix: PropTypes.string,
  isBigTable: PropTypes.bool,
  headerData: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

PureTable.defaultProps = {
  headerData: undefined,
  isBigTable: false,
  prefix: '',
};

export default PureTable;
