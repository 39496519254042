/* eslint-disable max-len */
import { UseQueryOptions } from 'react-query';
import httpClient from '../../helpers/http';

// enum MapMode {
//   Standart = 1,
//   UnderservedAreaReport = 2,
//   RuralAreaReport = 3,
//   CommunityCharter = 4,
// }
export interface IFormData {
  idEntities: number[];
  mapMode: 1 | 2 | 3 | 4;
  benchmarkTractIds: number[];
}
export interface IRequest {
  formData: IFormData;
}
export interface IRequestTable {
  formData: IFormData;
  currentPage: number;
  numberOfItems: number;
  sortBy: number;
  column: string;
}
export interface ILocationProfileData {
  locationId: string | null;
  guid: string | null;
}

export const GetLocationProfileData = ({
  locationId,
  guid,
}: ILocationProfileData): UseQueryOptions => ({
  queryKey: [`Location-Profile-${locationId || guid}`],
  queryFn: () =>
    httpClient.get(
      `api/locationProfile/locationsProfile${
        guid ? `?guid=${guid}` : `?locationId=${locationId}`
      }`
    ),
});
export const PostDemographicData = ({
  formData,
}: IRequest): UseQueryOptions => ({
  queryKey: [`Location-Profile-${formData.mapMode}`],
  queryFn: () => httpClient.post(`/api/locationprofile/demographic`, formData),
});

export const PostIncomeDetailsData = ({
  formData,
}: IRequest): UseQueryOptions => ({
  queryKey: [`Location-Profile-income-details-${formData.mapMode}`],
  queryFn: () =>
    httpClient.post(`/api/locationProfile/incomeDetails`, formData),
});

export const PostProfileData = ({ formData }: IRequest): UseQueryOptions => ({
  queryKey: [`Location-Profile-profile-details-${formData.mapMode}`],
  queryFn: () => httpClient.post(`api/locationProfile/profileData`, formData),
});

export const PostLocationDetails = ({
  formData,
}: IRequest): UseQueryOptions => ({
  queryKey: [
    `Location-Profile-top-level-locations-details-${formData.mapMode}`,
  ],
  queryFn: () =>
    httpClient.post(`api/locationProfile/topLevelLocationsDetails`, formData),
});

export const PostBusinessWorkedFor = ({
  formData,
}: IRequest): UseQueryOptions => ({
  queryKey: [`Location-Profile-business-worked-for-${formData.mapMode}`],
  queryFn: () =>
    httpClient.post(`api/locationProfile/businessessWorkedFor`, formData),
});

export const PostVeteranStatus = ({ formData }: IRequest): UseQueryOptions => ({
  queryKey: [`Location-Profile-veteran-status-${formData.mapMode}`],
  queryFn: () => httpClient.post(`api/locationProfile/veteranStatus`, formData),
});

export const PostOccupancyData = ({ formData }: IRequest): UseQueryOptions => ({
  queryKey: [`Location-Profile-occupancy-status-${formData.mapMode}`],
  queryFn: () =>
    httpClient.post(`api/locationProfile/unitsAndOccupancy`, formData),
});

export const PostFinancialInstitutions = ({
  formData,
  currentPage,
  numberOfItems,
  sortBy,
  column,
}: IRequestTable): UseQueryOptions => ({
  queryKey: [
    `Location-Profile-table`,
    formData.mapMode,
    currentPage,
    numberOfItems,
    sortBy,
    column,
  ],
  queryFn: () =>
    httpClient.post(
      `/api/locationProfile/financialInstitutions?currentPage=${currentPage}&numberOfItems=${numberOfItems}&sortBy=${sortBy}&column=${column}`,
      formData
    ),
  keepPreviousData: false,
  staleTime: Infinity,
});

export const PostLIDTable = ({
  formData,
  currentPage,
  numberOfItems,
  sortBy,
  column,
}: IRequestTable): UseQueryOptions => ({
  queryKey: [
    `Location-Profile-table`,
    formData.mapMode,
    currentPage,
    numberOfItems,
    sortBy,
    column,
  ],
  queryFn: () =>
    httpClient.post(
      `/api/locationProfile/lidPopulation?currentPage=${currentPage}&numberOfItems=${numberOfItems}&sortBy=${sortBy}&column=${column}`,
      formData
    ),
  keepPreviousData: false,
  staleTime: Infinity,
});
export const PostPopulationTableData = ({
  formData,
  currentPage,
  numberOfItems,
  sortBy,
  column,
}: IRequestTable): UseQueryOptions => ({
  queryKey: [
    `Location-Profile-cu-population-table-${formData.mapMode}-currentPage=${currentPage}-numberOfItems=${numberOfItems}-sortBy=${sortBy}-column=${column}`,
  ],
  queryFn: () =>
    httpClient.post(
      `/api/locationProfile/populationTable?currentPage=${currentPage}&numberOfItems=${numberOfItems}&sortBy=${sortBy}&column=${column}`,
      formData
    ),
  keepPreviousData: false,
  staleTime: Infinity,
});
export const PostCURelationships = ({
  formData,
  currentPage,
  numberOfItems,
  sortBy,
  column,
}: IRequestTable): UseQueryOptions => ({
  queryKey: [
    `Location-Profile-cu-relationship-table-${formData.mapMode}-currentPage=${currentPage}-numberOfItems=${numberOfItems}-sortBy=${sortBy}-column=${column}`,
  ],
  queryFn: () =>
    httpClient.post(
      `/api/locationProfile/cuRelationships?currentPage=${currentPage}&numberOfItems=${numberOfItems}&sortBy=${sortBy}&column=${column}`,
      formData
    ),
  keepPreviousData: false,
  staleTime: Infinity,
});

export const PostCensusTable = ({
  formData,
  currentPage,
  numberOfItems,
  sortBy,
  column,
}: IRequestTable): UseQueryOptions => ({
  queryKey: [
    `Location-Profile-census-table-${formData.mapMode}-currentPage=${currentPage}-numberOfItems=${numberOfItems}-sortBy=${sortBy}-column=${column}`,
  ],
  queryFn: () =>
    httpClient.post(
      `/api/locationProfile/selected2010CensusTracts?currentPage=${currentPage}&numberOfItems=${numberOfItems}&sortBy=${sortBy}&column=${column}`,
      formData
    ),
  keepPreviousData: false,
  staleTime: Infinity,
});
