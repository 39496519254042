import React, { FC, useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Loader from 'react-loader-spinner';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { appConfig } from '../../config/appConfig';

export interface IDownloadLink {
  dateRange?: string;
  newTab?: boolean;
  file?: string;
  isEnabled?: boolean;
  hrefLink?: string;
  btnText: string;
  style?: React.CSSProperties;
  icon: string;
  description: string;
  formData: IFormData;
}
export interface IFormData {
  idEntities: number[];
  mapMode: 1 | 2 | 3 | 4;
  benchmarkTractIds: number[];
}
const DownloadLink: FC<IDownloadLink> = ({
  hrefLink,
  style,
  btnText,
  icon,
  file,
  description,
  formData,
  isEnabled = false,
  newTab = true,
}) => {
  const [enabled, setEnable] = useState<boolean>(isEnabled);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const useStyles = makeStyles({
    btn: {
      borderColor: '#15e577',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      height: '48px',
      backgroundColor: 'white',
      '&:hover': {
        borderColor: '#564345',
        boxShadow: '0px 2px 15px rgb(0 0 0 / 35%)',
        borderRadius: '4px',
        color: '#212B36',
        background: 'white!important',
      },
    },
  });

  const handleClose = (): void => {
    setShowAlert(false);
  };

  function downloadEmployeeData(): void {
    if (
      formData.benchmarkTractIds.length > 2 ||
      formData.idEntities.length > 2
    ) {
      setShowAlert(true);
    }
    setEnable(true);

    const element = document.createElement('a');
    element.download = 'true';
    if (newTab) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.location.href = `${file}`;
      }
    } else {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      const fileLink = `${appConfig.api}${hrefLink}`;
      const stringToken = window.localStorage.getItem('token');
      let fileName = '';
      fetch(fileLink, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${stringToken}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          fileName = `${
            response?.headers
              ?.get('content-disposition')
              ?.split("filename*=UTF-8''")[1]
          }`;
          setEnable(false);
          return response.blob();
        })
        .then((blob) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link?.parentNode?.removeChild(link);
        });
    }
  }

  const classes = useStyles();
  const useStylesBootstrap = makeStyles({
    arrow: {
      color: '#212B36',
    },
    tooltip: {
      padding: '16px',
      backgroundColor: '#212B36',
      fontSize: '12px',
      color: 'white',
    },
  });

  const tooltipClass = useStylesBootstrap();

  return (
    <>
      <Dialog
        open={showAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Loading data may take time because many options are selected
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip placement="top" classes={tooltipClass} title={description} arrow>
        <Button
          disabled={enabled}
          startIcon={<img src={icon} alt="file-icon" />}
          size="large"
          className={classes.btn}
          variant="contained"
          style={style}
          color="secondary"
          onClick={() => downloadEmployeeData()}
        >
          {btnText}
          {enabled && (
            <Loader
              style={{ marginLeft: '12px', strokeWidth: '20px' }}
              type="Oval"
              height={17}
              width={17}
              color="#2e5596"
            />
          )}
        </Button>
      </Tooltip>
    </>
  );
};

export default DownloadLink;
