import { FC, ReactElement } from 'react';
import s from './style/CuInfoBlock.module.scss';

interface ICuInfoBlock {
  topData: ITopData;
}

interface ITopData {
  isContiguous: boolean;
  areaType:
    | 'County'
    | 'Census Tracts 2010'
    | 'Place'
    | 'Subdivisions'
    | 'State'
    | string;
  wrappedStatisticalArea: string;
  locationNames: string[];
}

const CuInfoBlock: FC<ICuInfoBlock> = ({ topData, children }) => {
  const ContiguousBlock = (): ReactElement => {
    if (topData.isContiguous) {
      return (
        <p
          className={`${s.icon__block} ${s.wrapper__block__text}`}
          style={{ marginTop: '6px' }}
        >
          Selected area is contiguous
          <span
            className={`${s.icon__circle}`}
            style={{ backgroundColor: '#008000' }}
          >
            <span className={`${s.icon__circle__check}`} />
          </span>
        </p>
      );
    }
    return (
      <p
        className={`${s.icon__block} ${s.wrapper__block__text}`}
        style={{ marginTop: '6px' }}
      >
        Selected area is not contiguous
        <span
          className={`${s.icon__circle}`}
          style={{ backgroundColor: '#FFA500' }}
        >
          <span className={`${s.icon__circle__item}`}>!</span>
        </span>
      </p>
    );
  };
  return (
    <div className={`${s.wrapper}`}>
      <div className={`${s.wrapper__primary}`}>
        <h3 className={`${s.wrapper__tittle}`}>{topData.areaType}</h3>
        <div className={`${s.wrapper__block}`}>
          <p className={`${s.wrapper__block__text}`}>
            {topData.wrappedStatisticalArea === null
              ? // eslint-disable-next-line max-len
                'The selected area is entirely comprised of locations outside of MSAs or CSAs.'
              : topData.wrappedStatisticalArea}
          </p>
          {ContiguousBlock()}
          <div className={`${s.wrapper__cuList} row`}>
            {topData.locationNames.map((i) => (
              <div className={`${s.wrapper__cuList__child} col-sm-3`} key={i}>
                {i}
              </div>
            ))}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default CuInfoBlock;
