import { FC } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import s from '../../style/LocationProfilePage.module.scss';
import Pie from './OccupancyPie';

type IOccupancyChart = {
  data: {
    numberOfHousingUnit: string;
    housingRental: number;
    housingHomeowner: number;
  };
  isLoading: boolean;
};

const OccupancyChart: FC<IOccupancyChart> = ({ data, isLoading }) => {
  const OwnershipArray = [
    {
      value: Math.round(data.housingHomeowner * 100) / 100,
      name: 'Owner occupied',
      itemStyle: {
        color: '#5890FF',
      },
    },
    {
      value: Math.round(data.housingRental * 100) / 100,
      name: 'Renter occupied',
      itemStyle: {
        color: '#2E5596',
      },
    },
  ];
  return (
    <div className={`${s.block} ${s.block__bar}`}>
      <div className={`${s.block__bar__header}`}>
        <h4 className={`${s.block__bar__header__title}`}>
          Housing units and occupancy
        </h4>
      </div>
      <div className={`${s.block__bar__body} row`}>
        <div className={`${s.block__bar__body__item} col-sm-5`}>
          <div className={`${s.block__bar__body__item__info}`}>
            <div className={`${s.block__bar__body__item__info__item}`}>
              <div className={`${s.block__bar__body__item__info__item__value}`}>
                {isLoading ? (
                  <Skeleton width="150px" />
                ) : (
                  data.numberOfHousingUnit
                )}
              </div>
              <div className={`${s.block__bar__body__item__info__item__label}`}>
                Number of housing units
              </div>
            </div>
          </div>
        </div>
        <div className={`${s.block__bar__body__item} col-sm-7`}>
          <div className={`${s.block__bar__body__item__title}`}>
            Type of Occupant
          </div>
          {isLoading ? (
            <Skeleton width="457px" height="218px" />
          ) : (
            <Pie
              styleProperty={{ maxWidth: '450px', width: '100%' }}
              pieTitle="Type of Occupant"
              pieData={OwnershipArray}
            />
          )}
        </div>
      </div>
    </div>
  );
};

OccupancyChart.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    housingHomeowner: PropTypes.number.isRequired,
    numberOfHousingUnit: PropTypes.string.isRequired,
    housingRental: PropTypes.number.isRequired,
  }).isRequired,
};

export default OccupancyChart;
