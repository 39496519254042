const env = process.env.REACT_APP_ENV as '1' | '3';

//   prod = 1,
//   test = 3, test on number 3 cause we have standart on other project where 1 is prod 2 is stage 3 is testqa (the same as we have on master layout config)

export const configObject = {
  '1': {
    clientId: 'locationProfile_prod',
    postLoginLink: 'https://app.cucollaborate.com',
    api: 'https://location-profile.azurewebsites.net',
    authLink: 'https://auth.cucollaborate.com',
  },
  '3': {
    clientId: 'locationProfile_test',
    postLoginLink: 'https://testqa.cucollaborate.com',
    api: 'https://location-profile-testqa1.cucollaborate.com',
    authLink: 'https://authtestqa.cucollaborate.com',
  },
};

export const appConfig = configObject[env];
