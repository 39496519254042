import { FC, useMemo, useRef } from 'react';
import Table, { ITableProps } from '../../../../components/Table';
import Accordion from '../../../../components/Accordion';
import s from '../../style/LocationProfilePage.module.scss';
import { PostCURelationships } from '../../../../API';

const RelationshipsTable: FC<ITableProps> = ({ formData, setIsLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Credit Union',
        accessor: 'creditUnionName',
        linkFormatter: (data: any) =>
          `CUCollaborate/CUDetails?accountIdToView=${data.row.original.creditUnionId}`,
      },
      {
        Header: 'Relationship',
        accessor: 'relationship',
        linkFormatter: (data: any) =>
          `FieldsOfMembership/ViewRules?accountIdToView=${data.row.original.creditUnionId}`,
      },
      {
        Header: 'Location',
        accessor: 'locationName',
        linkFormatter: (data: any) =>
          `LocationProfile/LocationsProfile/${data.row.original.locationId}`,
      },
    ],
    []
  );

  const wrapperElement = useRef<HTMLDivElement>(null);

  return (
    <div className={`${s.block} ${s.block__table}`} ref={wrapperElement}>
      <Accordion title="Credit Union Relationships" childrenExtraHeight="900">
        <div className={`${s.block__table__body}`}>
          <Table
            setIsLoading={setIsLoading}
            formData={formData}
            fetchFunction={PostCURelationships}
            focusElement={wrapperElement}
            columns={columns as any}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default RelationshipsTable;
