import { FC } from 'react';
import PropTypes, { number, bool } from 'prop-types';
import { Skeleton } from '@mui/material';
import BarChart from '../../../../components/BarChart';
import v from './style/VeteranStatus.module.scss';
import s from '../../style/LocationProfilePage.module.scss';

interface IVeteranInfoData {
  veteranTotalFemale: number;
  veteranTotalMale: number;
  veteranTotal: number;
  veteranPercent: number;
}
interface IVeteranStatus {
  chartData: number[];
  infoData: IVeteranInfoData;
  isLoading: boolean;
}
const VeteranStatus: FC<IVeteranStatus> = ({
  chartData,
  infoData,
  isLoading,
}) => (
  <div className={`${s.block} ${s.block__bar}`}>
    <div className={`${s.block__bar__header}`}>
      <h4 className={`${s.block__bar__header__title}`}>Veteran status</h4>
    </div>
    <div className={`${s.block__bar__body} row`}>
      <div className={`${s.block__bar__body__item} col-sm-3`}>
        <div className={`${s.block__bar__body__item__info}`}>
          <div className={`${s.block__bar__body__item__info__item}`}>
            <div className={`${s.block__bar__body__item__info__item__value}`}>
              {isLoading ? (
                <Skeleton width="100%" style={{ maxWidth: '188px' }} />
              ) : (
                `${infoData.veteranPercent}%`
              )}
            </div>
            <div className={`${s.block__bar__body__item__info__item__label}`}>
              Population with veteran status
            </div>
          </div>
        </div>
      </div>
      <div className={`${s.block__bar__body__item} col-sm-7`}>
        {isLoading ? (
          <span style={{ width: '100%', height: '100%' }}>
            <h4 className={`${s.block__bar__header__title}`}>
              Veterans by wartime service
            </h4>
            <Skeleton
              width="100%"
              height="88%"
              style={{ marginTop: '-30px' }}
            />
          </span>
        ) : (
          <BarChart
            isEmpty
            legendTitle={[
              {
                name: '',
                textStyle: {
                  color: '#2E5596',
                },
              },
            ]}
            data={{
              barWidth: '35%',
              emphasis: {
                focus: 'series',
              },
              blur: {
                itemStyle: {
                  color: 'rgba(33, 3, 3, 1)',
                },
              },
              name: '',
              type: 'bar',
              label: {
                show: true,
                color: 'inherit',
                position: 'top',
                valueAnimation: true,
                formatter: '{@score}%',
              },
              data: chartData,
            }}
            title="Veterans by wartime service"
            legendData={[
              'WWII',
              'Korean War',
              'Vietnam War',
              'First Gulf War',
              'Second Gulf War',
            ]}
          />
        )}
      </div>
      <div
        className={`${v.veterans} ${s.block__bar__body__item} col-sm-2`}
        style={{ marginBottom: '50px' }}
      >
        <div className={`${v.veterans__wrapper}`}>
          <span className={`${v.veterans__label}`}>Veterans</span>
          <div className={`${v.veterans__value}`}>
            {isLoading ? (
              <Skeleton width="53px" />
            ) : (
              infoData.veteranTotal?.toLocaleString('en')
            )}
          </div>
        </div>
        <div className={`${v.veterans__wrapper}`}>
          <span className={`${v.veterans__label}`}>Male</span>
          <div className={`${v.veterans__value}`}>
            {isLoading ? (
              <Skeleton width="53px" />
            ) : (
              infoData.veteranTotalMale?.toLocaleString('en')
            )}
          </div>
        </div>
        <div className={`${v.veterans__wrapper}`}>
          <span className={`${v.veterans__label}`}>Female</span>
          <div className={`${v.veterans__value}`}>
            {isLoading ? (
              <Skeleton width="53px" />
            ) : (
              infoData.veteranTotalFemale?.toLocaleString('en')
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

VeteranStatus.propTypes = {
  chartData: PropTypes.arrayOf(number.isRequired).isRequired,
  infoData: PropTypes.shape({
    veteranTotalFemale: number.isRequired,
    veteranTotalMale: number.isRequired,
    veteranTotal: number.isRequired,
    veteranPercent: number.isRequired,
  }).isRequired,
  isLoading: bool.isRequired,
};

export default VeteranStatus;
