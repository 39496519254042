import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { privateRoutes, RouteNames } from '../../router';

const AppRouter: FC = () => (
  <Switch>
    {privateRoutes.map((route) => (
      <Route
        path={route.path}
        exact={route.exact}
        component={route.component}
        key={route.path}
      />
    ))}
    <Redirect to={RouteNames.Page404Route} />
  </Switch>
);

export default AppRouter;
