/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import s from '../style/Sorting.module.scss';

type SortingType = {
  column: columnType;
};
type columnType = {
  isSorted: boolean;
  isSortedDesc?: boolean;
};
const Sorting: FC<SortingType> = ({ column }) => (
  <span className={`${s.sortable}`}>
    {column.isSorted
      ? column.isSortedDesc === undefined
        ? ''
        : column.isSortedDesc
        ? ' 🔽'
        : ' 🔼'
      : ''}
  </span>
);

export default Sorting;
