import { FC } from 'react';
import s from './style/progressBar.module.scss';

type PageWrapperType = {
  progressNumber: number;
};

const PageWrapper: FC<PageWrapperType> = ({ progressNumber }) => (
  <div className={`${s.progress__wrapper}`}>
    <h2
      className={`${s.progress__wrapper__done}
       ${s.progress__wrapper__done__label}`}
      style={{ width: `${progressNumber}%` }}
    >
      Loading
    </h2>
  </div>
);
export default PageWrapper;
