/* eslint-disable no-nested-ternary */
import React, { useState, useReducer, useEffect } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useQueries } from 'react-query';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import {
  LastPage,
  FirstPage,
  ArrowBackIosNew,
  ArrowForwardIos,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Sorting from './Sorting';
import { IFormData } from '../../API';
import s from './style/Table.module.scss';
import { appConfig } from '../../config/appConfig';

const dropdownStyles = makeStyles({
  root: {
    color: '#bdbdbd',
    boxShadow: 'none',
    border: 'none',
    '&::before': {
      border: 'none',
    },

    '&::hover': {
      border: 'none',
    },
    '&::after': {
      border: 'none',
    },
  },
});

export interface ColumnDefinitionType<T, K extends keyof T> {
  key: K;
  header: string;
  width?: number;
  linkFormatter?: (data: any) => string;
  reactLink?: boolean;
  rightCellAlign?: boolean;
  accessor: string;
}

type ITable<T, K extends keyof T> = {
  columns: Array<ColumnDefinitionType<T, K>>;
  fetchFunction: any; // UseQueryOptions;
  focusElement: React.RefObject<HTMLDivElement>;
  formData: IFormData;
  setIsLoading: (isLoading: boolean) => void;
};

// An enum with all the types of actions to use in our reducer
enum TableActionKind {
  PAGE_CHANGED = 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
  PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED',
  PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED',
  TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED',
  ACTIVE_COLUMN_CHANGED = 'ACTIVE_COLUMN_CHANGED',
}

type TableAction = {
  type: TableActionKind;
  payload: TableState;
};

type TableState = {
  queryPageIndex: number;
  queryPageSize: number;
  controlledPageCount: number;
  queryColumnName: string;
  queryPageSortBy: 0 | 1;
};

const reducer = (state: TableState, action: TableAction): TableState => {
  const { type, payload } = action;
  switch (type) {
    case TableActionKind.PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload.queryPageIndex,
      };
    case TableActionKind.PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload.queryPageSize,
      };
    case TableActionKind.PAGE_SORT_CHANGED:
      return {
        ...state,
        queryPageSortBy: payload.queryPageSortBy,
      };
    case TableActionKind.ACTIVE_COLUMN_CHANGED:
      return {
        ...state,
        queryColumnName: payload.queryColumnName,
      };
    case TableActionKind.TOTAL_COUNT_CHANGED:
      return {
        ...state,
        controlledPageCount: payload.controlledPageCount,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
const cellWithLinkRender = ({
  data,
  reactLink,
}: {
  data: any;
  reactLink: boolean;
}): JSX.Element => {
  if (reactLink) {
    return (
      <a
        href={`${data.column.linkFormatter(data)}`}
        className={`${s.table__body__item__link}`}
      >
        {data.value}
      </a>
    );
  }
  return (
    <a
      href={`${appConfig.postLoginLink}/${data.column.linkFormatter(data)}`}
      className={`${s.table__body__item__link}`}
    >
      {data.value}
    </a>
  );
};

const Table = <T, K extends keyof T>({
  columns,
  fetchFunction,
  focusElement,
  formData,
  setIsLoading,
}: ITable<T, K>): JSX.Element => {
  const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    controlledPageCount: 0,
    queryColumnName: columns[0]?.accessor,
    queryPageSortBy: 0,
  } as TableState;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    {
      queryPageIndex,
      queryPageSize,
      controlledPageCount,
      queryColumnName,
      queryPageSortBy,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const results = useQueries([
    fetchFunction({
      formData,
      currentPage: queryPageIndex + 1,
      numberOfItems: queryPageSize,
      sortBy: queryPageSortBy,
      column: queryColumnName,
    }),
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: columns as any,
      data: data as any[],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        // sortBy: queryPageSortBy,
      },
      pageCount: controlledPageCount,
      manualPagination: true,
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    const TableData = results[0].data as any;
    setLoading(results[0]?.isLoading);
    setIsLoading(results[0]?.isLoading);
    if (TableData) {
      const { pageViewModel } = TableData;
      if (controlledPageCount !== pageViewModel?.totalPages) {
        dispatch({
          type: TableActionKind.TOTAL_COUNT_CHANGED,
          payload: {
            ...initialState,
            controlledPageCount: pageViewModel?.totalPages,
          },
        });
      }
      setData(TableData.tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledPageCount, results]);

  useEffect(() => {
    dispatch({
      type: TableActionKind.PAGE_CHANGED,
      payload: {
        ...initialState,
        queryPageIndex: pageIndex,
      },
    });
    dispatch({
      type: TableActionKind.PAGE_SIZE_CHANGED,
      payload: {
        ...initialState,
        queryPageSize: pageSize,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  useEffect(() => {
    dispatch({
      type: TableActionKind.PAGE_SORT_CHANGED,
      payload: {
        ...initialState,
        queryPageSortBy: sortBy.length > 0 ? (sortBy[0].desc ? 0 : 1) : 1,
      },
    });
    dispatch({
      type: TableActionKind.ACTIVE_COLUMN_CHANGED,
      payload: {
        ...initialState,
        queryColumnName:
          sortBy.length > 0 ? sortBy[0].id : columns[0]?.accessor,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, sortBy]);

  const ddnSt = dropdownStyles();

  return (
    <>
      <table className={`${s.table}`} {...getTableProps()}>
        <thead className={`${s.table__header}`}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`${s.table__header__item}`}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                  style={{
                    textAlign: 'rightCellAlign' in column ? 'right' : 'initial',
                  }}
                >
                  {column.render('Header')}
                  <Sorting column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            // Use our custom loading state to show a loading indicator
            <tr className={`${s.table__loader}`}>
              <td colSpan={10000}>Loading...</td>
            </tr>
          ) : (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={`${s.table__body}`}>
                  {row.cells.map((cell) => (
                    <td
                      className={`${s.table__body__item}`}
                      style={{
                        textAlign:
                          'rightCellAlign' in cell.column ? 'right' : 'initial',
                      }}
                      {...cell.getCellProps()}
                    >
                      {'linkFormatter' in cell.column
                        ? cellWithLinkRender({
                            data: cell,
                            reactLink: 'reactLink' in cell.column,
                          })
                        : cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <div className={`${s.table__pagination}`}>
        <div>
          <Select
            classes={{
              root: ddnSt.root,
            }}
            style={{ color: '#bdbdbd' }}
            disableUnderline
            input={<Input />}
            value={pageSize}
            displayEmpty
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100].map((item) => (
              <MenuItem
                key={item}
                value={item}
                style={{ color: '#bdbdbd' }}
                onClick={() => {
                  setTimeout(() => {
                    focusElement?.current?.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }, 100);
                }}
              >
                {item} rows
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <IconButton
            onClick={() => {
              gotoPage(0);
              setTimeout(() => {
                focusElement?.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
            }}
            disabled={!canPreviousPage}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            onClick={() => {
              previousPage();
              setTimeout(() => {
                focusElement?.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
            }}
            disabled={!canPreviousPage}
          >
            <ArrowBackIosNew style={{ width: '16px', height: '16px' }} />
          </IconButton>
          <span className={s.table__pagination__pages}>
            {`Page `}
            {pageIndex + 1} of {pageOptions.length}
          </span>
          <IconButton
            onClick={() => {
              nextPage();
              setTimeout(() => {
                focusElement?.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
            }}
            disabled={!canNextPage}
          >
            <ArrowForwardIos style={{ width: '16px', height: '16px' }} />
          </IconButton>

          <IconButton
            onClick={() => {
              gotoPage(pageCount - 1);
              setTimeout(() => {
                focusElement?.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
            }}
            disabled={!canNextPage}
          >
            <LastPage />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default React.memo(Table);
export interface ITableProps {
  setIsLoading: (isLoading: boolean) => void;
  formData: IFormData;
}
