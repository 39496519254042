import { FC, useMemo, useRef } from 'react';
import Table, { ITableProps } from '../../../../components/Table';
import Accordion from '../../../../components/Accordion';
import s from '../../style/LocationProfilePage.module.scss';
import { PostFinancialInstitutions } from '../../../../API';

const FinancialInstitutions: FC<ITableProps> = ({ formData, setIsLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Area Name',
        accessor: 'areaName',
      },
      {
        Header: 'Depository institutions Name',
        accessor: 'financilaInstituionName',
      },
      {
        Header: 'DI Type',
        accessor: 'financialInstituionType',
      },
      {
        Header: 'Branch Name',
        accessor: 'branchName',
      },
      {
        Header: 'CU Field of Membership',
        accessor: 'cuFieldOfMembership',
      },
      {
        Header: 'Assets',
        accessor: 'assets',
      },
      {
        Header: 'LID Status',
        accessor: 'lidStatus',
      },
      {
        Header: 'CBSA',
        accessor: 'cbsaName',
      },
      {
        Header: 'Street',
        accessor: 'street',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'ZIP',
        accessor: 'zipCode',
      },
    ],
    []
  );
  const wrapperElement = useRef<HTMLDivElement>(null);

  return (
    <div className={`${s.block} ${s.block__table}`} ref={wrapperElement}>
      <Accordion title="Depository institutions" childrenExtraHeight="900">
        <div className={`${s.block__table__body}`}>
          <Table
            setIsLoading={setIsLoading}
            formData={formData}
            focusElement={wrapperElement}
            fetchFunction={PostFinancialInstitutions}
            columns={columns as any}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default FinancialInstitutions;
