/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AppRouter from './components/AppRouter/AppRouter';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AuthService from './helpers/client';
import CUCTheme from './styles/theme/index';
import './styles/App.scss';

const auth = new AuthService();
type GlobalData = {
  fullName: string;
  description: string;
  image?: string;
  activeCU: string;
  env: string;
  hiddenLinks?: string[];
  disableLinks?: string[];
  activeLink?: string;
};

declare global {
  interface Window {
    masterLayoutProps: GlobalData;
  }
}

function parseJwt(token: string): string {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

function waitForElm(selector: string) {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve): void => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
const sendGlobalData = (data: any) => {
  window.masterLayoutProps = data;
  const event = new CustomEvent('masterLayoutProps');
  window.dispatchEvent(event);
};
const silentLogout = (): void => {
  localStorage.setItem('redirectUrl', window.location.origin);
  auth.logout();
  localStorage.removeItem('token');
  localStorage.removeItem('idToken');
};

const App: FC = () => {
  const currentLocation = useLocation();
  const Token = localStorage.getItem('token');
  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    const logoutBtn = '#master_layout_logout_btn';
    waitForElm(logoutBtn).then((elm: any) => {
      elm.removeAttribute('href');
      elm?.addEventListener('click', silentLogout);
    });
    // TODO: remove fallback
    const logoutBtnFallback =
      '#master_layout_topBar > div > div > a:nth-child(3)';
    waitForElm(logoutBtnFallback).then((elm: any) => {
      elm.removeAttribute('href');
      elm?.addEventListener('click', silentLogout);
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setUserData(parseJwt(localStorage.getItem('token') as string));
    } else {
      if (process.env.REACT_APP_TEST_TOKEN) {
        localStorage.setItem('token', process.env.REACT_APP_TEST_TOKEN);
        return;
      }
      auth.login({ redirectUrl: window.location.href });
    }
  }, [Token]);

  useEffect(() => {
    sendGlobalData({
      fullName:
        userData !== undefined
          ? userData[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
            ]
          : '',
      description: userData?.usertitle,
      image: userData?.userlogo,
      activeCU: userData?.accountname,
      env: process.env.REACT_APP_ENV,
      activeLink: `/#${currentLocation.pathname}`,
    });
  }, [userData, currentLocation]);
  return (
    <ThemeProvider theme={CUCTheme}>
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;
