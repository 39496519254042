/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from 'react';
import ReactECharts from 'echarts-for-react';
import s from './style/Pie.module.scss';

interface IPie {
  title: string;
  data: DataItem[];
}

interface DataItem {
  name: string;
  value: number;
  itemStyle?: {
    color?: string;
  };
}

const Pie: FC<IPie> = ({ title, data }) => {
  const colorPalette: string[] = [
    '#1CC7D1',
    '#2e5596',
    '#917FFF',
    '#2CB392',
    '#5C6BC8',
    '#5890FF',
  ];

  const options = {
    fontFamily: 'Open Sans',
    tooltip: {
      trigger: 'item',
      backgroundColor: '#212b36',
      borderColor: '#212b36',
      textStyle: {
        color: '#FFFFFF',
      },
      padding: 16,
      formatter(params: any) {
        const pieName = params.name;
        const itemName = title;
        const colorSpan = (color: any): any =>
          `<span style="display:inline-block;margin-right:5px;
          border-radius:10px;width:9px;height:9px;
          background-color:${color}"></span>`;
        let rez = `<p style="font-size:18px;font-weight: 700;">${pieName}</p>`;
        const xx = `<p style="padding:0;margin:0">${colorSpan(
          params.data.name1 ? '#2E5596' : params.color
        )}${itemName}: ${params.data.value}% </p>`;
        rez += xx;
        return rez;
      },
      position(point: any, params: any, dom: any, rect: any, size: any) {
        // The tooltip is displayed to the right when the mouse is on the left
        // and the tooltip is displayed to the left when the mouse is
        // on the right
        const obj: any = { top: point[1] };
        obj[['left', 'right'][+(point[0] < size.viewSize[0] / 5)]] = 5;
        return obj;
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },
    legend: {
      align: 'left',
      width: '60%',
      bottom: 0,
      left: 'center',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '12px',
        color: '#373935',
      },
      orient: 'horizontal',
      icon: 'circle',
      itemGap: 16,
    },

    color: colorPalette,

    series: [
      {
        name: title,
        type: 'pie',
        radius: ['50%', '68%'],
        avoidLabelOverlap: false,
        legendHoverLink: false,
        center: ['50%', '35%'],
        labelLayout: {
          hideOverlap: true,
        },

        label: {
          normal: {
            width: '90',
            border: 100,
            show: true,
            position: 'center',
            formatter: '{b|{c}%}\n{a|{b}}',
            rich: {
              a: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontStyle: 'normal',
                fontSize: '12px',
                color: '#373935',
              },
              b: {
                padding: [0, 0, 6, 0],
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '26px',
                align: 'center',
                color: '#373935',
              },
            },
          },
          emphasis: {
            focus: 'series',
            blurScope: 'coordinateSystem',
            width: '90',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            show: true,
            formatter: '{b|{c}%}\n{a|{b}}',
            rich: {
              a: {
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '12px',
                color: '#373935',
              },
              b: {
                padding: [0, 0, 6, 0],
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '26px',
                align: 'center',
                color: '#373935',
              },
            },
          },
        },
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
          },
          emphasis: {
            label: {
              show: true,
            },
          },
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '10',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };

  return (
    <ReactECharts
      className={s.pie}
      option={options}
      opts={{ renderer: 'canvas' }}
    />
  );
};

export default Pie;
