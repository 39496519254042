import { FC, useMemo, useRef } from 'react';
import Table, { ITableProps } from '../../../../components/Table';
import Accordion from '../../../../components/Accordion';
import s from '../../style/LocationProfilePage.module.scss';
import { PostLIDTable } from '../../../../API';

const LIDPopulation: FC<ITableProps> = ({ formData, setIsLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'name',
        linkFormatter: (data: any) => `/?locationId=${data.row.original.id}`,
        reactLink: true,
      },
      {
        Header: 'Population',
        accessor: 'population',
        rightCellAlign: true,
      },
    ],
    []
  );
  const wrapperElement = useRef<HTMLDivElement>(null);

  return (
    <div className={`${s.block} ${s.block__table}`} ref={wrapperElement}>
      <Accordion
        title="Low Income Designated Population"
        childrenExtraHeight="900"
      >
        <div className={`${s.block__table__body}`}>
          <Table
            setIsLoading={setIsLoading}
            formData={formData}
            fetchFunction={PostLIDTable}
            focusElement={wrapperElement}
            columns={columns as any}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default LIDPopulation;
