import { FC } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import BarChart from '../../../../components/BarChart';
import s from '../../style/LocationProfilePage.module.scss';
import { IEducationalAttainments } from '../../../../models/IDemographic';

interface IEducationAttainmentsBlock {
  informationData: IEducationalAttainments<number>;
  chartData: number[];
  isLoading: boolean;
}
const EducationAttainments: FC<IEducationAttainmentsBlock> = ({
  informationData,
  chartData,
  isLoading,
}) => (
  <div className={`${s.block} ${s.block__bar}`}>
    <div className={`${s.block__bar__header}`}>
      <h4 className={`${s.block__bar__header__title}`}>Formal education</h4>
    </div>
    <div className={`${s.block__bar__body} row`}>
      <div className={`${s.block__bar__body__item} col-sm-5`}>
        <div className={`${s.block__bar__body__item__info}`}>
          <div className={`${s.block__bar__body__item__info__item}`}>
            <div className={`${s.block__bar__body__item__info__item__value}`}>
              {isLoading ? (
                <Skeleton width="100%" style={{ maxWidth: '170px' }} />
              ) : (
                `${informationData.populationGraduatedHighSchoolPercent}%`
              )}
            </div>
            <div className={`${s.block__bar__body__item__info__item__label}`}>
              High school graduate or higher
            </div>
          </div>
          <span
            className={` ${s.block__bar__body__item__info__item__underline}`}
          />
          <div className={`${s.block__bar__body__item__info__item}`}>
            <div className={`${s.block__bar__body__item__info__item__value}`}>
              {isLoading ? (
                <Skeleton width="100%" style={{ maxWidth: '170px' }} />
              ) : (
                `${informationData.populationWithBachelorDegreePercent}%`
              )}
            </div>
            <div className={`${s.block__bar__body__item__info__item__label}`}>
              Bachelor’s degree or higher
            </div>
          </div>
        </div>
      </div>
      <div className={`${s.block__bar__body__item} col-sm-7`}>
        {isLoading ? (
          <span style={{ width: '100%', height: '100%' }}>
            <h4 className={`${s.block__bar__header__title}`}>
              Formal education (% of members or population)
            </h4>
            <Skeleton
              width="100%"
              height="260px"
              style={{ marginTop: '-30px' }}
            />
          </span>
        ) : (
          <BarChart
            isEmpty
            legendTitle={[
              {
                name: '',
                textStyle: {
                  color: '#2E5596',
                },
              },
            ]}
            data={{
              barWidth: '35%',
              emphasis: {
                focus: 'series',
              },
              blur: {
                itemStyle: {
                  color: 'rgba(33, 3, 3, 1)',
                },
              },
              name: 'Formal education (% of members or population)',
              type: 'bar',
              label: {
                show: true,
                color: 'inherit',
                position: 'top',
                valueAnimation: true,
                formatter: '{@score}%',
              },
              data: chartData,
            }}
            title="Formal education (% of members or population)"
            legendData={[
              'Less than high school',
              'High school',
              'Some college',
              'Bachelor’s',
              'Graduate degree',
            ]}
          />
        )}
      </div>
    </div>
  </div>
);

EducationAttainments.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  informationData: PropTypes.shape({
    populationGraduatedHighSchoolPercent: PropTypes.number.isRequired,
    populationWithBachelorDegreePercent: PropTypes.number.isRequired,
  }).isRequired,
};
export default EducationAttainments;
