/* eslint-disable */
/* eslint-disable max-len */
import { FC } from 'react';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import s from './style/BarChart.module.scss';

interface DataItem {
  name: string;
  value: any[];
}

interface DataSeries {
  data: DataItem[];
}

export interface BarChartProps {
  data: object;
  title: string;
  legendTitle: any[];
  legendData: string[];
  showLegend?: boolean;
  isEmpty: boolean;
  styleProperty?: React.CSSProperties;
}

const BarChart: FC<BarChartProps> = ({
  showLegend = false,
  data,
  title,
  legendTitle,
  legendData,
  isEmpty,
  styleProperty,
}) => {
  const colorPalette = ['#2e5596', '#2CB392'];

  const options = {
    color: colorPalette,
    textStyle: {
      fontFamily: 'Open Sans',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#212b36',
      borderColor: '#212b36',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        color: '#FFFFFF',
      },
      padding: 16,
      formatter(params: any) {
        const colorSpan = (color: any): any =>
          `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`;
        let rez = `<p style="font-size:18px;font-family: Open Sans;font-weight: 700;">${params[0].axisValue}</p>`;
        params.forEach((item: any) => {
          const xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
            item.color
          )}${item.data}%</p>`;
          rez += xx;
        });

        return rez;
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      rich: {
        a: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '12px',
          color: '#373935',
        },
        b: {
          padding: [0, 0, 6, 0],
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '26px',
          align: 'center',
          color: '#373935',
        },
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },
    title: {
      z: 10,
      text: title,
      left: isEmpty ? 25 : 0,
      padding: 0,
      textStyle: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
    legend: {
      // Top tittles
      show: showLegend,
      data: legendTitle,
      itemHeight: 7,
      itemWidth: 18,
      right: '0',
      z: 20,
      width: '13%',
      align: 'right',
      reversed: true,
      verticalAlign: 'top',
      floating: true,
      padding: 4,
      symbolPadding: -3,
      itemDistance: 10,
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '12px',
      },
    },
    grid: {
      show: false,
      left: '0',
      right: '0',
      bottom: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          boundaryGap: true,
          lineStyle: {
            color: '#EFF2F7',
            width: 2,
          },
        },
        type: 'category',
        data: legendData,
        axisLabel: {
          interval: 0,
          // rotate: 30 //If the label names are too long you can manage this by rotating the label.
        },
        offset: 8,

        axisLine: {
          show: false,
          lineStyle: {
            color: '#95A7CB',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        splitLine: {
          show: false,
          boundaryGap: false,
        },
        type: 'value',
      },
    ],
    series: data,
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        ...styleProperty,
      }}
    >
      <div style={{ flex: 'auto', minHeight: '220px' }}>
        <ReactECharts
          option={options}
          notMerge
          lazyUpdate
          style={{
            marginTop: '4px',
            minHeight: '220px',
            width: '100%',
          }}
          opts={{ renderer: 'canvas' }}
        />
      </div>
    </div>
  );
};
export default BarChart;
