import { FC } from 'react';
import PropTypes from 'prop-types';
import s from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  titleName?: string;
  crumbs: Array<BreadcrumbsArrayConfiguration>;
};

export type BreadcrumbsArrayConfiguration = {
  name: string;
  path: string;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ titleName = '', crumbs }) => {
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className={`${s.breadcrumb}`}>
      <h2 className={`${s.breadcrumb__title} p-0`}>{titleName}</h2>
      <ol className={`${s.breadcrumb__source}`}>
        {crumbs.map(
          ({ name, path }: BreadcrumbsArrayConfiguration, key: number) =>
            key + 1 === crumbs.length ? (
              <li
                key={path}
                className={`breadcrumb-item ${s.breadcrumb__source__item} `}
              >
                <strong className={`${s.breadcrumb__source__item__active} `}>
                  {name}
                </strong>
              </li>
            ) : (
              <li
                key={path}
                className={`breadcrumb-item ${s.breadcrumb__source__item} `}
              >
                <span>{name}</span>
              </li>
            )
        )}
      </ol>
    </div>
  );
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  titleName: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  titleName: '',
};

export default Breadcrumbs;
