import { FC, useMemo, useRef } from 'react';
import Table, { ITableProps } from '../../../../components/Table';
import Accordion from '../../../../components/Accordion';
import s from '../../style/LocationProfilePage.module.scss';
import { PostPopulationTableData } from '../../../../API';

const PopulationTable: FC<ITableProps> = ({ formData, setIsLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'locationName',
        linkFormatter: (data: any) =>
          `/?locationId=${data.row.original.entityId}`,
        reactLink: true,
      },
      {
        Header: 'Population',
        accessor: 'population',
        rightCellAlign: true,
      },
    ],
    []
  );

  const wrapperElement = useRef<HTMLDivElement>(null);

  return (
    <div className={`${s.block} ${s.block__table}`} ref={wrapperElement}>
      <Accordion title="Population" childrenExtraHeight="900">
        <div className={`${s.block__table__body}`}>
          <Table
            formData={formData}
            setIsLoading={setIsLoading}
            focusElement={wrapperElement}
            fetchFunction={PostPopulationTableData}
            columns={columns as any}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default PopulationTable;
