import { FC } from 'react';
import PropTypes from 'prop-types';
import s from '../../style/LocationProfilePage.module.scss';
import Pie from '../Pie';
import PureTable from '../../../../components/PureTable';
import TableAccordion from '../../../../components/TableAccordion';
import Accordion from '../../../../components/Accordion';
import { ILanguageSpokenAtHome } from '../../../../models/IDemographic';
import LoadingPieChart from '../LoadingPIeChart/index';

interface ILanguageChart {
  languageChartArray: ILanguageSpokenAtHome<number>;
  languageTableArray: ILanguageSpokenAtHome<string>;
  isLoading: boolean;
}

const LanguageChart: FC<ILanguageChart> = ({
  languageChartArray,
  languageTableArray,
  isLoading,
}) => {
  const languageData = [
    {
      value: Math.round(languageChartArray.english * 100) / 100,
      name: 'English only',
    },
    {
      value: Math.round(languageChartArray.spanish * 100) / 100,
      name: 'Spanish',
    },
    {
      value: Math.round(languageChartArray.indoEuropean * 100) / 100,
      name: 'Other Indo-European',
    },
    {
      value: Math.round(languageChartArray.asian * 100) / 100,
      name: 'Asian and Pacific Islander',
    },
    {
      value: Math.round(languageChartArray.other * 100) / 100,
      name: 'Other',
    },
  ];

  const tableData = [
    {
      value: languageTableArray.english,
      name: 'English only',
    },
    {
      value: languageTableArray.spanish,
      name: 'Spanish',
    },
    {
      value: languageTableArray.indoEuropean,
      name: 'Other Indo-European',
    },
    {
      value: languageTableArray.asian,
      name: 'Asian and Pacific Islander',
    },
    {
      value: languageTableArray.other,
      name: 'Other',
    },
  ];
  return isLoading ? (
    <LoadingPieChart title="Language Spoken at Home" />
  ) : (
    <div className={`${s.block} ${s.block__pie}`}>
      <Accordion title="Language Spoken at Home" childrenExtraHeight="900">
        <div className={s.block__pie__body}>
          <Pie title="Language Spoken at Home" data={languageData} />
          <div className={s.block__pie__body__table}>
            <TableAccordion>
              <>
                <h4 className={`${s.table__pie__title}`}>
                  Language Spoken at Home
                </h4>
                <PureTable
                  data={tableData}
                  headerData={['Language', 'Population']}
                />
              </>
            </TableAccordion>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

LanguageChart.propTypes = {
  languageChartArray: PropTypes.shape({
    english: PropTypes.number.isRequired,
    spanish: PropTypes.number.isRequired,
    indoEuropean: PropTypes.number.isRequired,
    asian: PropTypes.number.isRequired,
    other: PropTypes.number.isRequired,
  }).isRequired,
  languageTableArray: PropTypes.shape({
    english: PropTypes.string.isRequired,
    spanish: PropTypes.string.isRequired,
    indoEuropean: PropTypes.string.isRequired,
    asian: PropTypes.string.isRequired,
    other: PropTypes.string.isRequired,
  }).isRequired,
};

export default LanguageChart;
