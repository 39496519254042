import { FC } from 'react';
import PropTypes from 'prop-types';
import s from '../../style/LocationProfilePage.module.scss';
import Pie from '../Pie';
import Accordion from '../../../../components/Accordion';
import TableAccordion from '../../../../components/TableAccordion';
import PureTable from '../../../../components/PureTable';
import { IGenderPopulation } from '../../../../models/IDemographic';
import LoadingPieChart from '../LoadingPIeChart/index';

interface IGenderChart {
  data: IGenderPopulation<number>;
  isLoading: boolean;
}

const GenderChart: FC<IGenderChart> = ({ data, isLoading }) => {
  const genderPieData = [
    {
      value: Math.round(data.malePopulationPercent * 100) / 100,
      name: 'Male',
    },
    {
      value: Math.round(data.femalePopulationPercent * 100) / 100,
      name: 'Female',
    },
  ];

  return isLoading ? (
    <LoadingPieChart title="Gender" />
  ) : (
    <div className={`${s.block} ${s.block__pie}`}>
      <Accordion title="Gender" childrenExtraHeight="900">
        <div className={s.block__pie__body}>
          <Pie title="Gender" data={genderPieData} />
          <div className={s.block__pie__body__table}>
            <TableAccordion>
              <>
                <h4 className={`${s.table__pie__title}`}>Gender</h4>
                <PureTable
                  data={genderPieData}
                  prefix="%"
                  headerData={['Gender', 'Value']}
                />
              </>
            </TableAccordion>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

GenderChart.propTypes = {
  data: PropTypes.shape({
    malePopulationPercent: PropTypes.number.isRequired,
    femalePopulationPercent: PropTypes.number.isRequired,
  }).isRequired,
};

export default GenderChart;
