import { FC } from 'react';
import { Skeleton } from '@mui/material';
import s from './style/InfoBlock.module.scss';

interface IFormula {
  result: string;
  top: string;
  down: string;
}

interface IInfoBlockData {
  name: string;
  value: any;
  valueType: string;
}

interface IValueContent {
  value: IFormula;
}

interface IInfoBlock {
  title: string;
  data: IInfoBlockData[];
}

const InfoBlock: FC<IInfoBlock> = ({ title, data }) => {
  const list = ({ value }: { value: string[] }): JSX.Element => (
    <ul className={`${s.list}`}>
      {value.map((i) => (
        <li key={i}> {i}</li>
      ))}
    </ul>
  );

  const formula = ({ value }: IValueContent): JSX.Element => (
    <span className={s.fraction}>
      <div>
        <span className={s.top}>{value.top}</span>
        <span className={s.bottom}>{value.down}</span>
      </div>
      <div className={s.result}> ={value.result}</div>
    </span>
  );
  const content = ({
    value,
    valueType,
  }: {
    value: any;
    valueType: string;
  }): any => {
    switch (valueType) {
      case 'loading':
        return <Skeleton width="100%" style={{ minWidth: '40px' }} />;
      case 'value':
        return value;
      case 'list':
        return list({ value: value as string[] });
      case 'formula':
        return formula({ value: value as IFormula });
      default:
        return value;
    }
    return '';
  };

  const infoContent = (): JSX.Element[] =>
    data.map((i) => (
      <div className={`${s.info__block__field}`} key={i.name}>
        <span>{i.name}:</span>
        <span>{content({ value: i.value, valueType: i.valueType })}</span>
      </div>
    ));

  function camelize(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, '');
  }

  return (
    <div className={`${s.info}`} id={camelize(title)}>
      <h4 className={`${s.info__tittle}`}>{title}</h4>
      <div className={`${s.info__block}`}>{infoContent()}</div>
    </div>
  );
};

export default InfoBlock;
